import { Component, Mixins } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { Action, Getter, Mutation } from 'vuex-class';
import FilterMixin from '@/mixins/filter.mixin';
import BoxHeader from '@/components/ui/box-header/boxHeader.vue';
import { ReportsProductDetailsRequest } from '@/types/request/reports-request.types';
import { DateTime } from 'luxon';
import {
  DATEPICKER_FORMAT,
  FILTER_MONTH_YEAR_FORMAT,
  getPrevMonthsDateRange,
  MONTH_FORMAT
} from '@/helpers/date.helper';
import BarChart from '@/components/ui/charts/bar-chart/barChart.vue';
import { ChartData } from 'chart.js';
import { COLOR_LIGHT, COLORS } from '@/constants/color.constant';
import DatepickerSingle from '@/components/ui/inputs/datepicker-single/datepickerSingle.vue';
import { ProductDetailsType } from '@/types/api/reports/product-details.type';
import { ProductsGetRequest } from '@/types/request/products-request.types';
import { ProductType } from '@/types/api/products/product.type';
import { BrandType } from '@/types/api/brands/brand.type';
import { processHEX } from '@/helpers/color.helper';
import { numberFormatter } from '@/helpers/string.helper';

const title = 'revenuePpcReport.title';

@Component({
  name: 'RevenuePpcReport',
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    BoxHeader,
    BarChart: BarChart as any,
    DatepickerSingle
  },
})
export default class RevenuePpcReport extends Mixins(FilterMixin) {
  title = title;
  pageFilter: any = {
    brandId: this.$route.query.brandId || null
  }
  filter: any = this.initFilter();

  @Action('getProductDetails', { namespace: 'reports' })
  getProductDetails!: (params?: ReportsProductDetailsRequest) => Promise<ProductDetailsType>;

  @Getter('getProductDetails', { namespace: 'reports' })
  productDetails!: ProductDetailsType;

  @Mutation('SET_PRODUCT_DETAILS', { namespace: 'reports' })
  setProductDetails!: (params: ProductDetailsType | null) => any;

  @Action('getProducts', { namespace: 'products' })
  getProducts!: (params?: ProductsGetRequest) => Promise<ProductType[]>;

  @Getter('getProducts', { namespace: 'products' })
  products!: ProductType[];

  @Getter('getBrands', { namespace: 'brands' })
  brands!: BrandType[];

  created() {
    this.filter.from = this.$route.query.from || this.startMonthDate;
    this.filter.to = this.$route.query.to || this.endMonthDate;
    if (this.brands.length === 1) {
      this.pageFilter.brandId = this.brands[0].id;
    }
    if (this.pageFilter.brandId) {
      this.getProductsRequest(this.pageFilter.brandId);
    }
    if (this.filter.from && this.filter.to && this.filter.productId) {
      this.getProductDetailsRequest();
    }
  }

  beforeDestroy() {
    this.setProductDetails(null);
  }

  getProductDetailsRequest() {
    return this.getProductDetails(this.preparedFilter);
  }

  getProductsRequest(value) {
    this.getProducts({ brandId: value });
  }

  get preparedFilter() {
    return {
      ...this.filter,
      from: DateTime.fromFormat(this.filter.from, DATEPICKER_FORMAT).toFormat(FILTER_MONTH_YEAR_FORMAT),
      to: DateTime.fromFormat(this.filter.to, DATEPICKER_FORMAT).toFormat(FILTER_MONTH_YEAR_FORMAT),
    }
  }

  get startMonthDate() {
    return DateTime.local().setZone('utc').startOf('month').toFormat(DATEPICKER_FORMAT);
  }

  get endMonthDate() {
    return DateTime.local().endOf('month').toFormat(DATEPICKER_FORMAT);
  }

  initFilter() {
    return {
      from: this.$route.query.from || null,
      to: this.$route.query.to || null,
      productId: this.$route.query.productId || null,
    };
  }

  get statistics() {
    return this.productDetails?.statistics || [];
  }

  get chartData(): ChartData {
    return {
      labels: Object.keys(this.statistics).map(item => item),
      datasets: [
        {
          label: 'Revenue incl. PPC ad spend, $',
          type: 'bar',
          data: Object.values(this.statistics).map((item: any) => item.spend) as any,
          borderColor: COLORS[0],
          backgroundColor: `rgba(${processHEX(COLORS[0]).join(',')}, 0.3)`,
          yAxisID: 'y1'
        },
        {
          label: 'Orders, #',
          type: 'line',
          data: Object.values(this.statistics).map((item: any) => item.ppcOrders) as any,
          borderColor: COLORS[2],
          backgroundColor: COLORS[2],
          yAxisID: 'y2'
        },
      ]
    };
  }

  get chartOptions() {
    return {
      tooltips: {
        enabled: false
      },
      hover: {
        animationDuration: 0
      },
      animation: {
        duration: 1,
        onComplete: ({ chart }) => {
          const ctx = chart.ctx;
          ctx.textAlign = 'center';
          ctx.fillStyle = "rgb(0,0,0)";
          ctx.textBaseline = 'bottom';

          this.chartData.datasets?.forEach((item, i) => {
            const meta = chart.controller.getDatasetMeta(i);
            if (!meta.hidden) {
              const sign = meta.yAxisID === 'y2' ? '' : '$';
              meta.data.forEach((bar, index) => {
                const value = meta.yAxisID === 'y2' ? item.data?.[index] : numberFormatter(item.data?.[index] as number, { fractionPartCount: 0 });
                ctx.fillText(
                  item.data?.[index] ? `${sign} ${ value }` : '',
                  bar._model.x,
                  bar._model.y
                );
              });
            }
          })
        }
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false,
            color: COLOR_LIGHT,
          },
        }],
        yAxes: [
          {
            id: 'y1',
            gridLines: {
              display: false,
              color: COLOR_LIGHT,
            },
            ticks: {
              beginAtZero: true
            },
            afterFit: () => 1050
          },
          {
            id: 'y2',
            position: 'right',
            gridLines: {
              display: false,
              color: COLOR_LIGHT,
            },
            ticks: {
              beginAtZero: true,
            },
            afterFit: () => 1050,
          }
        ]
      }
    }
  }

  get brandOptions() {
    return [
      { text: '---', value: null },
      ...this.brands.map(item => ({ text: item.displayName, value: item.id }))
    ];
  }

  get productOptions() {
    return [
      { text: '---', value: null },
      ...this.products.map(item => ({ text: item.displayName || item.name || item.asin, value: item.id }))
    ];
  }

  get monthFormat() {
    return MONTH_FORMAT;
  }

  getRanges() {
    const ranges = {};

    for (let i = 0; i < 12; i++) {
      const month = DateTime.local().minus({ months: i });
      const year = month.get('year');
      const label = year !== DateTime.local().get('year') ? `${month.monthLong} ${year}` : month.monthLong;

      if (year === DateTime.local().get('year')) {
        ranges[label] = getPrevMonthsDateRange(i, true);
      }
    }

    return ranges;
  }

  onSelectBrand(value) {
    this.getProductsRequest(value);
  }
}
